import axios from '@axios'

export default {
  namespaced: true,
  state: {
    deletableTask: null,
    confirmationModalShow: false,
    isTaskDeleteLoading: false,
  },
  getters: {
    getDeletableTask: state => state.deletableTask,
    getConfirmationModalShow: state => state.confirmationModalShow,
    getIsTaskDeleteLoading: state => state.isTaskDeleteLoading,
  },
  mutations: {
    SET_DELETABLE_TASK: (state, val) => {
      state.deletableTask = val
    },
    SET_CONFIRMATION_MODAL_SHOW: (state, val) => {
      state.confirmationModalShow = val
    },
    SET_IS_TASK_DELETE_LOADING: (state, val) => {
      state.isTaskDeleteLoading = val
    },
  },
  actions: {
    fetchApplicationTaskList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/auth/application-tasks', {
            params: queryParams,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchTaskList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/auth/tasks', {
            params: queryParams,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchProgramList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/auth/programs', {
            params: queryParams,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
