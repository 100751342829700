var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"newTaskRules"},[_c('b-modal',{ref:"new-task-modal",attrs:{"id":"new-task-modal","centered":"","title":_vm.title,"ok-only":"","ok-title":_vm.okTitle,"ok-disabled":_vm.isLoading,"no-close-on-backdrop":""},on:{"show":_vm.setTaskData,"ok":_vm.handleForm,"hidden":_vm.resetModalData}},[_c('b-overlay',{attrs:{"id":"overlay-background","show":_vm.isLoading,"variant":"white","opacity":"1","rounded":"sm"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Task Name"}},[_c('validation-provider',{attrs:{"name":'task name',"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Name","state":errors.length > 0 ? false:null},model:{value:(_vm.task.name),callback:function ($$v) {_vm.$set(_vm.task, "name", $$v)},expression:"task.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Groups"}},[_c('validation-provider',{attrs:{"name":"task groups","rules":{ required: _vm.isAssignTaskGromGroups }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{class:{'is-invalid': errors.length > 0 },attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.groupsList,"clearable":false,"label":"name","value":"id","multiple":"","close-on-select":false,"placeholder":_vm.fieldGroupStatus,"reduce":function (group) { return group.id; }},model:{value:(_vm.task.group_ids),callback:function ($$v) {_vm.$set(_vm.task, "group_ids", $$v)},expression:"task.group_ids"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Scope"}},[_c('validation-provider',{attrs:{"name":'task scope',"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.scopesList,"clearable":false,"label":"text","value":"value","reduce":function (scope) { return scope.value; },"placeholder":'Select Scope',"multiple":true,"state":errors.length > 0 ? false:null},model:{value:(_vm.task.scope),callback:function ($$v) {_vm.$set(_vm.task, "scope", $$v)},expression:"task.scope"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Form"}},[_c('validation-provider',{attrs:{"name":'task form',"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.formsList,"clearable":false,"label":"name","value":"id","reduce":function (form) { return form.id; },"placeholder":_vm.fieldFormStatus,"state":errors.length > 0 ? false:null},model:{value:(_vm.task.form_id),callback:function ($$v) {_vm.$set(_vm.task, "form_id", $$v)},expression:"task.form_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Due date"}},[_c('validation-provider',{attrs:{"name":'task due date',"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"placeholder":"Select Date","state":errors.length > 0 ? false:null},model:{value:(_vm.task.date),callback:function ($$v) {_vm.$set(_vm.task, "date", $$v)},expression:"task.date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }