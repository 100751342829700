<template>
  <ValidationObserver
    ref="newTaskRules"
  >
    <b-modal
      id="new-task-modal"
      ref="new-task-modal"
      centered
      :title="title"
      ok-only
      :ok-title="okTitle"
      :ok-disabled="isLoading"
      no-close-on-backdrop
      @show="setTaskData"
      @ok="handleForm"
      @hidden="resetModalData"
    >
      <b-overlay
        id="overlay-background"
        :show="isLoading"
        variant="white"
        opacity="1"
        rounded="sm"
      >
        <b-row>

          <b-col cols="12">
            <b-form-group
              label="Task Name"
            >
              <validation-provider
                #default="{ errors }"
                :name="'task name'"
                rules="required"
              >
                <b-form-input
                  v-model="task.name"
                  placeholder="Name"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group
              label="Groups"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="task groups"
                :rules="{ required: isAssignTaskGromGroups }"
              >
                <v-select
                  v-model="task.group_ids"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="groupsList"
                  :clearable="false"
                  label="name"
                  value="id"
                  multiple
                  :close-on-select="false"
                  :placeholder="fieldGroupStatus"
                  :reduce="group => group.id"
                  :class="{'is-invalid': errors.length > 0 }"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group
              label="Scope"
            >
              <validation-provider
                #default="{ errors }"
                :name="'task scope'"
                rules="required"
              >
                <v-select
                  v-model="task.scope"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="scopesList"
                  :clearable="false"
                  label="text"
                  value="value"
                  :reduce="scope => scope.value"
                  :placeholder="'Select Scope'"
                  :multiple="true"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group
              label="Form"
            >
              <validation-provider
                #default="{ errors }"
                :name="'task form'"
                rules="required"
              >
                <v-select
                  v-model="task.form_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="formsList"
                  :clearable="false"
                  label="name"
                  value="id"
                  :reduce="form => form.id"
                  :placeholder="fieldFormStatus"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group
              label="Due date"
            >
              <validation-provider
                #default="{ errors }"
                :name="'task due date'"
                rules="required"
              >
                <flat-pickr
                  v-model="task.date"
                  class="form-control"
                  placeholder="Select Date"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>

            </b-form-group>
          </b-col>
        </b-row>
      </b-overlay>
    </b-modal>
  </ValidationObserver>
</template>

<script>
import {
  BModal,
  BOverlay,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import useNewTaskModal from '@/views/components/new-task-modal/useNewTaskModal'

export default {
  name: 'NewTaskModal',
  components: {
    ValidationProvider,
    ValidationObserver,
    BModal,
    BOverlay,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    flatPickr,
  },
  props: {
    isAssignTaskGromGroups: {
      type: Boolean,
      default: false,
    },
    isTaskEditing: {
      type: Boolean,
      default: false,
    },
    editableTask: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isLoading: false,
      task: {
        name: '',
        scope: '',
        group_ids: [],
        form_id: '',
        date: '',
      },
      formsList: [],
      fieldFormStatus: 'Loading...',
      groupsList: [],
      fieldGroupStatus: 'Loading...',
      scopesList: [
        {
          value: 'Staff',
          text: 'Staff',
        },
        {
          value: 'Camper',
          text: this.camperStudent(1),
        },
      ],
      required,
    }
  },
  computed: {
    title() {
      return this.isTaskEditing ? 'Edit Task' : 'Assign task'
    },
    okTitle() {
      return this.isTaskEditing ? 'Edit' : 'Assign'
    },
    programId() {
      return this.$store.getters['verticalMenu/getDefaultProgram']
    },
  },
  setup() {
    const {
      fetchGroups,
      fetchForms,
      fetchTask,
      createTask,
      updateTask,
    } = useNewTaskModal()

    return {
      fetchGroups,
      fetchForms,
      fetchTask,
      createTask,
      updateTask,
    }
  },
  async created() {
    await this.fetchFormsList()
    await this.fetchGroupsList()
  },
  methods: {
    setTaskData() {
      if (this.isTaskEditing) {
        this.task = JSON.parse(JSON.stringify(this.editableTask))
        this.task.form_id = this.editableTask.form.id
      }
    },
    async fetchFormsList() {
      this.formsList = await this.fetchForms({
        withoutProgramForms: true,
      })
      this.fieldFormStatus = 'Select Form'
    },
    async fetchGroupsList() {
      const queryParam = {
        program_id: this.programId,
        exceptProgramType: 'Program',
      }
      this.groupsList = await this.fetchGroups(queryParam)
      this.fieldGroupStatus = 'Select Groups'
    },
    handleForm(bvModalEvt) {
      bvModalEvt.preventDefault()
      // eslint-disable-next-line consistent-return
      return new Promise((resolve, reject) => {
        this.$refs.newTaskRules.validate().then(success => {
          if (success) {
            resolve(true)
            if (this.isTaskEditing) {
              this.handleUpdateTask()
            } else {
              this.handleCreateTask()
            }
          } else {
            reject()
          }
        })
      })
    },
    async handleCreateTask() {
      this.isLoading = true
      const queryParams = {
        program_id: this.programId,
        ...this.task,
      }

      await this.createTask(queryParams)
        .then(() => {
          this.$emit('success')
        })
        .finally(() => {
          this.isLoading = false
          this.$bvModal.hide('new-task-modal')
        })
    },
    async handleUpdateTask() {
      this.isLoading = true
      const queryParams = {
        program_id: this.programId,
        ...this.task,
      }

      await this.updateTask(queryParams)
        .then(() => {
          this.$emit('success')
        })
        .finally(() => {
          this.isLoading = false
          this.$bvModal.hide('new-task-modal')
        })
    },
    resetModalData() {
      this.task = {
        name: '',
        scope: '',
        group_ids: [],
        form_id: '',
        date: '',
      }
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
